import useBookingCart from '@/hooks/use-booking-cart';
import useTranslation from '@/hooks/use-translation';
import { Box } from '@drivekyte/ui';
import Hero from '../../../../../../../public/images/home-page/hero.webp';
import SearchMobile from '../../../search-mobile';

type HeroMobileProps = {
  headline?: string;
  subHeader?: string;
  ctaText?: string;
  description?: string;
};

const HeroMobile = ({
  headline,
  ctaText,
  description,
  subHeader,
}: HeroMobileProps) => {
  const { t } = useTranslation('pages.home-page.hero');

  const bookingCart = useBookingCart();

  return (
    <Box
      paddingHorizontal="$medium"
      paddingVertical="$xxLarge"
      backgroundColor="$green700"
    >
      <SearchMobile
        headline={headline ?? t('headline')}
        subHeader={subHeader}
        description={description ?? t('description')}
        backgroundImageSrc={Hero}
        ctaText={ctaText ?? t('search.cta')}
        backgroundImageAltText="Hero image"
        startAddressLabel={
          bookingCart.isSameDeliveryReturnLocations
            ? t('search.where.same-address.label')
            : t('search.where.start-address.label')
        }
        startAddressPlaceholder={
          bookingCart.isSameDeliveryReturnLocations
            ? t('search.where.same-address.placeholder')
            : t('search.where.start-address.placeholder')
        }
        startAddressModalTitle={
          bookingCart.isSameDeliveryReturnLocations
            ? t('search.where.same-address.modal-title')
            : t('search.where.start-address.modal-title')
        }
        returnAddressLabel={t('search.where.end-address.label')}
        returnAddressPlaceholder={t('search.where.end-address.placeholder')}
        returnAddressModalTitle={t('search.where.end-address.modal-title')}
      />
    </Box>
  );
};

export default HeroMobile;
