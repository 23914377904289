import Page from '@/components/page';
import useBase64EncodedData from '@/hooks/use-base64-encoded-data.ts';
import useGetRentalSearchHistory from '@/hooks/use-get-rental-search-history';
import useMe from '@/hooks/use-me';
import useTranslation from '@/hooks/use-translation';
import { Box, getTokenValue, useMedia } from '@drivekyte/ui';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import CarClassesSection from './components/car-classes-section';
import DownloadSection from './components/download-section';
import HeroSection from './components/hero-section';
import { SearchbarToggleProvider } from './components/nav-bar/desktop-nav-bar-search/search-bar-context';
import NavBar from './components/nav-bar/nav-bar';
import ValuesPropsSection from './components/values-props-section';
import {
  MainPageTitleExperimentResponse,
  MainPageTitleExperimentVariantsEnum,
} from './hooks/use-main-page-title-experiment/use-main-page-title-experiment';

const LocationsSection = dynamic(() =>
  import('./components/locations-section'),
);
const FaqSection = dynamic(() => import('./components/faq-section'));

const TestimonialsSection = dynamic(() =>
  import('./components/testimonials-section'),
);

const HowItWorksSection = dynamic(() =>
  import('./components/how-it-works-section'),
);

type HomePageProps = {
  experimentData: MainPageTitleExperimentResponse;
};

const PageWrapper = styled.div`
  overflow: hidden;
`;
const HomePage = ({ experimentData }: HomePageProps) => {
  const { t } = useTranslation('pages.home-page');
  const smallSpacing = getTokenValue('$small', 'space');
  const { me } = useMe();
  const media = useMedia();

  useGetRentalSearchHistory({
    uuid: me?.uuid,
    enabled: !!me?.uuid,
  });

  const base64Data = useBase64EncodedData();
  const heroHeadLine =
    experimentData?.variant_name ===
    MainPageTitleExperimentVariantsEnum.TREATMENT
      ? experimentData.variant_attachment.variant
      : base64Data?.h1;

  return (
    <PageWrapper>
      <SearchbarToggleProvider>
        <NavBar />
      </SearchbarToggleProvider>
      <Page
        title={t('page-title')}
        top={{
          mobile: 0,
          web: !media.gtMd ? smallSpacing * 5 : 0,
        }}
      >
        <Box id="book-a-car-section">
          <HeroSection
            headline={heroHeadLine}
            ctaText={base64Data?.cta}
            description={base64Data?.description}
          />
          <Box
            paddingVertical="$xLarge"
            paddingHorizontal="$medium"
            gap="$huge"
            $gtMd={{
              paddingHorizontal: '$xHuge',
              gap: '$xHuge',
            }}
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            maxWidth={1440}
            width="100vw"
          >
            <ValuesPropsSection />
            <DownloadSection />
            <CarClassesSection />
            <HowItWorksSection />
            <TestimonialsSection />
            <LocationsSection />
            <FaqSection />
          </Box>
        </Box>
      </Page>
    </PageWrapper>
  );
};

export default HomePage;
